<template>
    <div class="annuaire__view">
        <div class="header">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher un thème" single-line hide-details></v-text-field>
            <v-btn @click="$router.push({path: '/theme/add'})">
                Ajouter un thème
            </v-btn>
        </div>
        <v-data-table :headers="headers" :items="themes" :search="search" :footer-props="{
    itemsPerPageOptions: [10,20,30]
  }" class="elevation-1">
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click="$router.push({path: '/theme/' + item.id})" title="Modifier le thème">
                    mdi-pencil
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: 'Annuaire',
    data() {
        return {
            themes: [],
            uploadCSV: null,
            loading: true,
            loadingAdd: false,
            loadingAddCsv: false,
            search: '',
            newAnnuaire: {
                url: '',
                login: '',
                password: ''
            },
            options: {
                page: 1,
                sortBy: ['created'],
                itemsPerPage: 15,
                sortDesc: [true],
            },
            headers: [
                { text: 'Nom', value: 'name' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
        }
    },
    watch: {
        search: {
            handler() {
                this.loadData()
            }
        },
        options: {
            handler() {
                this.loadData()
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions("global", [
            "getThemesAction"
        ]),
        loadData() {
            var _this = this;
            this.loading = true;
            this.getThemesAction()
            .then(res => {
                _this.themes = res.data
            })
            .finally(() => {
                _this.loading = false
            })
        }
    },
    created() {
        this.loadData()
    }
}
</script>
<style lang="scss" scoped>
.annuaire__view {
    width: 80%;
    margin: 20px auto;
    .header {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .v-input {
            margin: 0;
            padding: 0;
        }
        .v-btn {
            margin: 0 0 0 20px;
        }
    }
    .flex__adder {
        display: flex;
        gap: 10%;
        form {
            width: 50%;
        }
    }
    form {
        .v-btn {
            margin: 0 0 10px 10px;
        }
        div {
            display: flex;
            justify-content: flex-end;
        }
    }
}
</style>